  <template>
    <v-app style="max-width:425px; margin-left:auto; margin-right:auto">
      <div class="loginClass" style="margin: 20px">
        <div style="position:relative;" align="right">
          <v-select
            style="width:20%; float:right;"
            :items="LanguageList"
            v-model="Language"
            item-text="item-text"
            item-value="item-value"
            @change="changeLanguage"
            solo
            >
            <template v-slot:selection="{ item, index }">
              <img :src="item.image">
           </template>
          </v-select>
        </div>

        <div>
           <div
            style="width: 10%; padding-top: 6px"
            align="left"
            v-on:click="redirectToLandingPage()"
          >
            <img
              src="https://img.icons8.com/material-outlined/20/000000/left.png"
            /></div>
          <h1 style="text-align: left; margin-top: 10px; font-size:30px">{{ $t("Login.LoginPage.Sign_In") }}</h1>
          <p style="font-size: 110%; text-align: left; margin-top: 16px">
            {{ $t("Login.LoginPage.Welcome_Back") }}
          </p>
        </div>
        <div style="margin-top: 48px" align="left">
          <div>
            <p class="font_size_12_400">{{ $t("Login.LoginPage.Mobile_Number") }}</p>
            <img
            src="https://d3vsnl8idgwrlw.cloudfront.net/Mobile_Number.svg"
            align="left"
            class="MobileNumber"
            style="
            width: 26px;
            height: 26px;
            margin-top:20px
            "
            />
            <v-col cols="12">
              <v-text-field
              background-color="#F4F6FA"
              prefix="+95"
              placeholder="09*********"
              type="number"
              style="margin-top: -2%; margin-left: 5%"
              :rules="$store.state.locale == 'en' ? mobileNumberRules : mobileNumberRulesMM"
              v-model="phone_number"
              >
              </v-text-field>
            </v-col>
          </div>
          <br />
          <div class="Password" v-show=false>
            <p class="font_size_12_400">{{ $t("Login.LoginPage.Password") }}</p>
            <img
            src="https://d3vsnl8idgwrlw.cloudfront.net/user_password.svg"
            align="left"
            style="
            fill: #000;
            position: absolute;
            left: 0;
            margin-left: 16px;
            width: 26px;
            height: 26px;
            "
            />
            <v-col cols="12" sm="6" md="3">
              <v-text-field
              :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
              background-color="#F4F6FA"
              :type="showPwd ? 'text' : 'password'"
              @click:append="showPwd = !showPwd"
              style="margin-top: -9%; margin-left: 5%"
              :rules="passwordRules"
              v-model="password"
              >
              </v-text-field>
            </v-col>
          </div>
      </div>
    <div>
      <v-btn
      elevation=""
      class="signup"
      color="primary"
      large
      v-bind:disabled="phone_number.length > 11 || phone_number.length < 7 || isLoginClicked"
      style="width: 100%"
      @click="LoginWithOTP"
      >
          {{ $t("Login.LoginPage.Login") }}
      </v-btn>
    </div>
    <div v-if="isLoginClicked">
      <v-progress-circular
        indeterminate
        color="primary"
        style="margin-top: 5px;"
      ></v-progress-circular>
      <p>{{ $t("Login.LoginPage.Sending_OTP") }}</p>
    </div>
  <div align="center" style="padding-top: 20px">
    <div v-show="false">
      <a
      href="/customer/resetPassword/"
      style="
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      "
      >{{ $t("Login.LoginPage.Forgot_Password") }}</a
      >
    </div>
    <div style="padding: 10px">
      <span style="font-size: 14px; font-weight: 700; line-height: 16.94px"
      >{{ $t("Login.LoginPage.New_User") }} </span
      ><a href="/signup" style="text-decoration: none">{{ $t("Login.LoginPage.Signup") }}</a>
    </div>
  </div>
</div>
<div>
  <v-row justify="center">
    <v-dialog v-model="auth_error_enable" persistent max-width="290">
      <v-card>
        <v-card-title style="word-break: normal">
          {{errorMessage}}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div  style="display:flex; flex-direction:row; justify-content: space-between; width:100%">
            <div>
              <v-btn class="primary" text @click="closeDialogUnauthorized" >
                {{$t("Warning_Message.Signup")}}
              </v-btn>
            </div>
            <div>
              <v-btn class="primary" text @click="auth_error_enable = false;" >
               {{$t("Warning_Message.Cancel")}}
              </v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</div>
<div>
  <v-row justify="center">
    <v-dialog v-model="phoneNumberError" max-width="290">
      <v-card>
        <v-card-title style="word-break:normal">
           {{$t("Warning_Message.Please_Enter_Your_Number_With0In_The_Beginning")}}
        </v-card-title>
        <v-card-text
        ></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            align="center"
            depressed
            color="primary"
            @click="disableLoading"
            >
           {{$t("Warning_Message.Ok")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</div>
<div>
  <v-row justify="center">
    <v-dialog v-model="error_enable" max-width="290">
      <v-card>
        <v-card-title style="word-break: normal">
           {{$t("Warning_Message.Something_Went_Wrong")}}
          </v-btn>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="closeDialogError"
          >
             {{$t("Warning_Message.Ok")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</div>
</v-app>
</template>

<script>

import axios from "axios";
export default {
  data() {
    return {
      phone_number: "",
      password: "",
      auth_error_enable: false,
      error_enable: false,
      showPwd:false,
      LanguageList: [{"item-text":"English","item-value":"en","image":"https://d3vsnl8idgwrlw.cloudfront.net/usa_flag.svg"},{"item-text":"Burmese","item-value":"mm","image": "https://d3vsnl8idgwrlw.cloudfront.net/myanmar_flag.svg"}],
      Language: "",
      passwordRules: [v => !!v || "Password is required"],
      mobileNumberRules: [
                        v => !!v || 'Mobile Number is required',
                        v => (v && v.length <= 11) || 'Mobile Number should be between 7 to 11 numbers',
                        v => v > 0 || 'Mobile number cannot be negative',
                        ],
      mobileNumberRulesMM: [
                        v => !!v || 'ဖုန်းနံပါတ်ထည့်သွင်းရန် လိုအပ်ပါသည်။',
                        v => (v && v.length <= 11) || 'ဖုန်းနံပါတ်၏ ဂဏန်းအရေအတွက်မှာ ၇ လုံးမှ ၁၁ လုံးဖြစ်ရပါမည်။',
                        v => v > 0 || 'Mobile number cannot be negative',
                        ],
      errorMessage : "",
      phoneNumberError: false,
      customerUnauthorized : false,
      invalidCredentials : false,
      isLoginClicked : false,
      isLoading : false
    };
  },
  methods: {
    disableLoading(){
      this.phoneNumberError = false;
      this.isLoading = false;
      this.isLoginClicked = false;
    },
    redirectToLandingPage(){
      this.$router.go(-1);
    },
    changeLanguage(){
      this.$i18n.locale = this.Language;
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    },
    Login() {
      this.isLoginClicked = true;
      this.isLoading = true;
      let encryptedText = this.CryptoJS.AES.encrypt(this.password,process.env.VUE_APP_ENCRYPTION_KEY).toString()
      var customerLoginData = {
        mobileNumber: this.phone_number,
        token: "",
        typeOfUser: "CUSTOMER",
        password : encryptedText
      };
      axios
      .post(
        process.env.VUE_APP_BACKEND_URL + "/customerSignIn",
        customerLoginData
        )
      .then((customerLoginData) => {
        this.isLoading = false

        this.$cookies.set("customerToken", customerLoginData.data.token,"1h");
        this.$cookies.set("access",customerLoginData.data.accessToken);


          this.$router.push({
            path: "/customer/teleconsultationHome",
          });
        })
      .catch((customerLoginException) => {
        this.isLoginClicked = false
        this.isLoading = false
       if(customerLoginException.response.status == 400 || customerLoginException.response.status == 303 || customerLoginException.response.status == 401){
             this.auth_error_enable = true;
             if(this.Language == 'en'){
               this.errorMessage = "You do not have a registered account";
             }
             else{
               this.errorMessage="သင်အကောင့်မဖွင့်ရသေးပါ"
             }

             this.customerUnauthorized = true;
       }
       else if(customerLoginException.response.status == 306){
          this.auth_error_enable = true;
          if(this.Language == 'en'){
            this.errorMessage = "Invalid Credentials";
          }
          else{
            this.errorMessage = "Invalid Credentials"
          }

          this.invalidCredentials = true;

       }
      });
    },
    closeDialogError(){
      this.error_enable = false;
    },
    LoginWithOTP(){
      this.isLoginClicked = true;
      if(this.phone_number[0] == "0")
      {
        var otpBody = {
          phoneNumber: this.phone_number
        };
        axios.post(process.env.VUE_APP_BACKEND_URL+"/checkIfPhoneNumberExists",otpBody)
        .then((checkIfPhoneNumberExistsResponse) =>{
          this.isLoginClicked = false
          this.isLoading = false
          this.auth_error_enable = true;
          if(this.Language == 'en'){
             this.errorMessage = "You do not have a registered account";
          }
          else{
            this.errorMessage = "သင်အကောင့်မဖွင့်ရသေးပါ"
          }

          this.customerUnauthorized = true;
        })
        .catch((checkIfPhoneNumberExistsError) =>{
          if(checkIfPhoneNumberExistsError.response.status == 305){
            axios.post(process.env.VUE_APP_BACKEND_URL+"/sendOTP",otpBody)
            .then((sendOTPResponse) =>{
              this.$router.push({
                name: 'OTPLogin',
                params: {
                  'phone_number': this.phone_number
                }
              });
            })
            .catch((sendOTPError) =>{
              this.error_enable = true;
            });
          }
          else
          {
            this.error_enable = true;
          }
        });
      }
      else
      {
        this.phoneNumberError = true;
      }
    },
    closeDialogUnauthorized() {
      this.$router.push({
        path: "/signup",
      });
    },
    closeDialogInvalidCredentials(){
      this.invalidCredentials = false;
      this.auth_error_enable = false;
    }
  },
  mounted() {
    this.currentCustomer = this.$cookies.get("customerToken");
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.Language = this.$i18n.locale;
    var access = this.$cookies.get("access");
    if(this.currentCustomer){

        var verifyCustomerTokenData = {
            "token" : this.currentCustomer,
            "typeOfUser" : "CUSTOMER"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",verifyCustomerTokenData)
        .then((verifyCustomerTokenDataResponse) => {
          this.$router.push({
            path: "/customer/teleconsultationHome",
          });
        })
        .catch((verifyCustomerTokenDataError)=>{
          this.$cookies.remove("customerToken");
          this.$cookies.remove("access");
        });
    }
    else{
      this.$cookies.remove("customerToken");
      this.$cookies.remove("access");
    }
  },

};
</script>

<style scoped>
.font_size_12_400 {
  font-size: 14px;
  font-weight: 400;
}
.mx-input {
  height: 40px !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
/* @media (min-width: 800px){
  .loginClass{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }
  .MobileNumber{
    margin-top:14px !important
  }
  } */
</style>
